<template>
    <div></div>
</template>

<script>
export default {
    mounted() {
        // Extract the query parameters
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        // Pass the code and state back to the main window
        if (window.opener && code) {
            window.opener.postMessage(
                { code },
                window.location.origin // Only allow communication to the same origin
            );
        }

        // Close the current window
        window.close();
    },
};
</script>
